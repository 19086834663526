import { Component } from '@angular/core';
import { fadeIn } from '@shared/animations';

@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  animations: [ fadeIn ],
})
export class AccordionComponent {
  isTermsAndConditionsOpen: boolean = true;
  isFAQOpen: boolean = false;
  isNeedMoreHelpOpen: boolean = false;

  encodedEmailAddress:string = "aGVscEBzZWF0ZnJvZy5jb20="
  emailAddress:string = "";

  ngOnInit(): void {
    this.emailAddress = atob(this.encodedEmailAddress);
  }
}
