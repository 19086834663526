import { Component } from '@angular/core';

@Component({
  selector: 'app-main',
  styleUrls: ['./main.component.scss'],
  template: `
    <main class="main-container">
      <router-outlet></router-outlet>
    </main>
  `
})
export class MainComponent {
  constructor() { }
}
