import { Action, createReducer, on } from '@ngrx/store';
import {
  clearActiveTrip,
  clearActiveTripEmail,
  clearActiveTripTimeband,
  clearStationsList,
  getStationsListSuccess,
  resetAllTripData,
  searchAvailableTripsSuccess,
  setActiveTripData,
  setActiveTripEmail,
  setActiveTripTemplate,
  setActiveTripTimeband,
  setCheckoutCompleted,
  setStationsList,
  updateTripDate
} from './store.actions';

import { AppState } from './store.state';

export const initialState: AppState = {
  stations: null,
  activeTripCalendarTemplate: null,
  activeTripData: null,
  checkoutCompleted: null,
};

const reducer = createReducer(
  initialState,
  on(getStationsListSuccess, (state, { data }) => ({
    ...state,
    stations: data
  })),
  on(searchAvailableTripsSuccess, (state, { data }) => ({
    ...state,
    activeTripCalendarTemplate: data
  })),
  on(setStationsList, (state, { stations }) => ({
    ...state,
    stations: stations
  })),
  on(setActiveTripTemplate, (state, { activeTrip }) => ({
    ...state,
    activeTripCalendarTemplate: activeTrip
  })),
  on(setActiveTripData, (state, { tripData }) => ({
    ...state,
    activeTripData: tripData
  })),
  on(clearStationsList, (state) => ({
    ...state,
    stations: null
  })),
  on(clearActiveTrip, (state) => ({
    ...state,
    activeTripCalendarTemplate: null,
    activeTripData: null,
  })),
  on(updateTripDate, (state, {date}) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      date: date
    }
  })),
  on(setActiveTripTimeband, (state, {timeband}) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      timeband: timeband
    }
  })),
  on(clearActiveTripTimeband, (state) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      timeband: null
    }
  })),
  on(setActiveTripEmail, (state, { email }) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      email: email
    }
  })),
  on(clearActiveTripEmail, (state) => ({
    ...state,
    activeTripData: {
      ...state.activeTripData,
      email: null
    }
  })),
  on(setCheckoutCompleted, (state, { completed }) => ({
    ...state,
    checkoutCompleted: completed,
  })),
  on(resetAllTripData, (state) => ({
    ...state,
    activeTripCalendarTemplate: null,
    activeTripData: null,
    checkoutCompleted: null,
  })),
);

export const appReducer = (state: AppState = initialState, action: Action) => reducer(state, action);
