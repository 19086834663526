<div class="cookie-consent" *ngIf="showCookieConsentBanner">
  <div class="cookie-consent__container">
    <h1 class="cookie-consent__title">Choose your cookies</h1>
    <div class="cookie-consent__content">
      <div class="cookie-consent__message">
        <p>Sorry not the chocolate kind, but the ones on this website. Some are essential to run it, others are up to you. Please tell us what you want.</p>
      </div>
      <div class="cookie-consent__buttons">
        <button (click)="allowNonEssentialCookies()" aria-label="Allow non-essential cookies">All cookies</button>
        <button (click)="declineNonEssentialCookies()" aria-label="Decline non-essential cookies">Essential cookies only</button>
      </div>
    </div>
  </div>
</div>