import { Component, OnInit} from '@angular/core';
import { LocalStorageEnum } from '@shared/enums/local-storage.enum';
import dayjs from 'dayjs';

@Component({
  selector: 'app-cookie-consent-banner',
  templateUrl: './cookie-consent-banner.component.html',
  styleUrls: ['./cookie-consent-banner.component.scss']
})
export class CookieConsentBannerComponent implements OnInit {
  private cookiePreferenceLifeTimeInMonths = 6;
  private cookiePreferenceExpiryDate = dayjs().add(this.cookiePreferenceLifeTimeInMonths, 'months').toISOString();
  public showCookieConsentBanner: boolean = true;

  ngOnInit(): void {

    if (localStorage.getItem(LocalStorageEnum.NonEssentialCookiesAllowed)) {
      //User has previously stated their preference to allow/deline non-essential cookies
      //No need to ask again unless preference has expired

      if (this.hasCookiePreferenceExpired()){
        this.invalidateNonEssentialCookiePreference();
        this.showCookieConsentBanner = true;
      } else {
        this.showCookieConsentBanner = false;
      }

    } else {
      //We found no record of the user's preference for non-essential cookies - need to ask again.
      this.showCookieConsentBanner = true;
    }
  }

  hasCookiePreferenceExpired(): boolean {
    const cookieAcceptanceExpiryDateTime = localStorage.getItem(LocalStorageEnum.NonEssentialCookiesPreferenceExpiryDateTime);
    const now = dayjs();

    if (!cookieAcceptanceExpiryDateTime)
      return true;

    return (dayjs(cookieAcceptanceExpiryDateTime) <= now)  
  }

  invalidateNonEssentialCookiePreference(): void {
    localStorage.removeItem(LocalStorageEnum.NonEssentialCookiesAllowed);
    localStorage.removeItem(LocalStorageEnum.NonEssentialCookiesPreferenceExpiryDateTime);
  }

  allowNonEssentialCookies(): void {
    this.showCookieConsentBanner = false;
    localStorage.setItem(LocalStorageEnum.NonEssentialCookiesAllowed, 'true')
    localStorage.setItem(LocalStorageEnum.NonEssentialCookiesPreferenceExpiryDateTime, this.cookiePreferenceExpiryDate);
    location.reload();
  }

  declineNonEssentialCookies(): void {
    this.showCookieConsentBanner = false;
    localStorage.setItem(LocalStorageEnum.NonEssentialCookiesAllowed, 'false')
    localStorage.setItem(LocalStorageEnum.NonEssentialCookiesPreferenceExpiryDateTime, this.cookiePreferenceExpiryDate);
    location.reload();
  }
}
