import { createFeatureSelector } from "@ngrx/store";

export const selectBookingState = createFeatureSelector<AppState>('booking');

export interface AppState {
    stations: any | null,
    activeTripCalendarTemplate: any | null,
    activeTripData: any | null,
    checkoutCompleted: boolean | null,
}
