import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private toastrService: ToastrService,) { }


  public showWarningToast(message: string) {
    this.toastrService.warning(message);
  }

  public showSuccessToast(message: string) {
    this.toastrService.success(message);
  }

  public showInfoToast(message: string) {
    this.toastrService.info(message);
  }

  public showErrorToast(message: string) {
    this.toastrService.error(message);
  }
}
