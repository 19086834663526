import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  template: `<div class="container">
      <div class="spinner"
        [class.small]="small"
        [style.border-color]="color ? color : 'inherit'">
      </div>
    </div>`,
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() color?: string;
  @Input() small?: boolean;
  constructor() { }
}
