import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type IconSizeTypes =
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'small2'
  | 'medium'
  | 'large'
  | 'large2'
  | 'flavor'
  | 'xlarge'
  | 'xlarge2'
  | 'original'
  | 'enormous';
  
@Component({
  selector: 'app-svg-icon',
  styleUrls: ['./svg-icon.component.scss'],
  template: `
  <div class="svg-icon {{ extraClass }}" [style.width.px]="width" [style.height.px]="height">
    <svg attr.width="{{width}}" attr.height="{{height}}" class="size-{{size}}">
      <use attr.xlink:href="#{{ icon }}" class="fill-{{fill}} stroke-{{stroke}}"></use>
    </svg>
  </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent {
  @Input() size: IconSizeTypes | undefined;

  @Input() icon: string | undefined;
  @Input() extraClass: string | undefined;

  @Input() width: number | undefined;
  @Input() height: number | undefined;
  @Input() fill: string | undefined;
  @Input() stroke: string | undefined;
}
