import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  constructor(private router: Router) {}

  linkToHowItWorsks() {
    this.router.navigate(['/']).then(() => {
      setTimeout(() => {
        const element = document.querySelector('.how-it-works');
        if (element) element.scrollIntoView({behavior: 'smooth'});
      }, 200);
    })
  }
}
