import { createAction, props } from "@ngrx/store";

// Fetch stations list
export const getStationsList = createAction('[App] Get station list');
export const getStationsListSuccess = createAction('[App] Get station list success', props<{ data: any }>());
export const getStationsListFailure = createAction('[App] Get station list failure', props<{ errors: any }>());

// Fetch calendar template
export const searchAvailableTrips = createAction('[App] Search available trips', props<{ date: string, origin: string, destination: string, numberOfTravellers: number }>());
export const searchAvailableTripsSuccess = createAction('[App] Search available trips success', props<{ data: any }>());
export const searchAvailableTripsFailure = createAction('[App] Search available trips failure', props<{ errors: any }>());

// Init payment session
export const initCheckoutSession = createAction('[App] Init checkout session', props<{ data: any }>());
export const initCheckoutSessionSuccess = createAction('[App] Init checkout session success', props<{ data: any }>());
export const initCheckoutSessionFailure = createAction('[App] Init checkout session failure', props<{ errors: any }>());

export const setStationsList = createAction('[App] Set stations list', props<{ stations: any[] }>());

export const setActiveTripTemplate = createAction('[App] Set active trip template', props<{ activeTrip: any }>());

export const setActiveTripData = createAction('[App] Set active trip data', props<{ tripData: any }>());

export const clearStationsList = createAction('[App] Clear stations list');

export const clearActiveTrip = createAction('[App] Clear active trip');

export const updateTripDate = createAction('[App] Update trip date', props<{ date: string }>());

export const setActiveTripTimeband = createAction('[App] Set active trip timeband', props<{ timeband: any }>());

export const clearActiveTripTimeband = createAction('[App] Clear active trip timeband');

export const setActiveTripEmail = createAction('[App] Set active trip email', props<{ email: string }>());

export const clearActiveTripEmail = createAction('[App] Clear active trip email');

export const setCheckoutCompleted = createAction('[App] Set checkout completed', props<{ completed: boolean }>());

export const resetAllTripData = createAction('[App] Reset all trip data');
