import { MomentDateAdapter } from "@angular/material-moment-adapter";

export class MondayFirstDateAdapter extends MomentDateAdapter {
  /**
   * Ensure we start the week on a Monday
   */
  getFirstDayOfWeek(): number {
    return 1;
  }

  /**
   * Use three letter abbreviations for day names instead of 2
   */
  getDayOfWeekNames(): string[] {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  }
}
