export enum RoutingEnum {
  PrivacyPolicy = 'https://seatfrog.com/privacy/',
  FAQ = 'faq',
  TermsAndConditions = 'terms-and-conditions',
  PassengerCharter = 'https://www.avantiwestcoast.co.uk/about-us/policies-and-procedures/passenger-charter',
  Help = 'https://help.seatfrog.com/',
  Booking = 'booking',
  BookTravelAssistance = 'https://www.railhelp.co.uk/avantiwestcoast/passenger-assist',
  DelayRepay = 'https://delayrepay.avantiwestcoast.co.uk/',
  Confirm = 'confirm',
  Purchase = 'purchase',
}
