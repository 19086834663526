import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-svg-definitions',
  styles: [`
    :host {
      display: none;
    }
  `],
  templateUrl: './svg-definitions-icons.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgDefinitionsComponent { }
