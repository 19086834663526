import { Pipe, PipeTransform } from '@angular/core';
import { selectStations } from '@core/store/store.selectors';
import { AppState } from '@core/store/store.state';
import { Store } from '@ngrx/store';
import { firstValueFrom, map } from 'rxjs';

@Pipe({
  name: 'stationName',
})
export class StationNamePipe implements PipeTransform {
  constructor(private store$: Store<AppState>) {}

  transform(value: string): Promise<string> {
    const source$ = this.store$.select(selectStations).pipe(
      map((stations) => {
        const matchedStation = stations?.find(
          (station: Station) => station.crs === value
        );
        return matchedStation?.name ?? value;
      })
    );

    return firstValueFrom(source$);
  }
}

interface Station {
  crs: string;
  name: string;
}
