import { Component } from '@angular/core';

@Component({
  selector: 'app-container',
  styleUrls: ['./container.component.scss'],
  template: `
    <div class="container">
      <app-header></app-header>
      <app-alert></app-alert>
      <app-cookie-consent-banner></app-cookie-consent-banner>
      <app-main></app-main>
      <app-footer></app-footer>
    </div>
  `
})
export class ContainerComponent {}
