<header>
  <div class="wrapper">
    <div routerLink="/">
      <img src="assets/images/Superfare.svg" class="logo" alt="Superfare - logo" loading="eager">
    </div>
    <div class="links">
        <a
          class="mr-30"
          (click)="linkToHowItWorsks()">How it works</a>
        <a
          class="mr-30"
          href="https://help.seatfrog.com/"
          target="_blank">Help centre</a>
    </div>
    <div routerLink="/" class="right">
      <img src="assets/images/Avanti_West_Coast.svg" alt="Avanti West Coast - logo" loading="eager">
    </div>
  </div>
</header>
