import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { MessageService } from './message.service';
import { catchError } from "rxjs/operators";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  private stationsUrl = environment.STATIONS_API_URL;
  private flexUrl = environment.FLEX_API_URL;
  private clientCode = environment.CLIENT_CODE;
  private stationsClientCode = "VtWeb";

  constructor(
    private httpClient: HttpClient,
    private messageService: MessageService,
  ) { }

  public fetchStationsList(): Observable<any> {
    let url = this.stationsUrl + "/flex/{partnerCode}";
    url = url.replace("{partnerCode}", encodeURIComponent("" + this.stationsClientCode));
    url = url.replace(/[?&]$/, "");

    return this.httpClient
      .get(url)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMsg: string = this.getServerErrorMessage(error);
          this.messageService.showErrorToast(errorMsg);
          return throwError(errorMsg);
      })
    );
  }

  public fetchDestinationStations(originCode: string) {
    let url = this.stationsUrl + "/flex/{partnerCode}?origin={originCode}";

    if (originCode === undefined || originCode === null) {
      const msg = 'The parameter \'originCode\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    url = url.replace("{partnerCode}", encodeURIComponent("" + this.stationsClientCode));
    url = url.replace("{originCode}", encodeURIComponent("" + originCode));
    url = url.replace(/[?&]$/, "");

    return this.httpClient
    .get(url)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMsg: string = this.getServerErrorMessage(error);
        this.messageService.showErrorToast(errorMsg);
        return throwError(errorMsg);
    })
  );
  }

  public fetchOriginStations(destinationCode: string) {
    let url = this.stationsUrl + "/flex/{partnerCode}?destination={destinationCode}";

    if (destinationCode === undefined || destinationCode === null) {
      const msg = 'The parameter \'destinationCode\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    url = url.replace("{partnerCode}", encodeURIComponent("" + this.stationsClientCode));
    url = url.replace("{destinationCode}", encodeURIComponent("" + destinationCode));
    url = url.replace(/[?&]$/, "");

    return this.httpClient
    .get(url)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMsg: string = this.getServerErrorMessage(error);
        this.messageService.showErrorToast(errorMsg);
        return throwError(errorMsg);
    })
  );
  }

  public searchForDepartures(
    date: string,
    origin: string,
    destination: string,
    numberOfTravellers: number
  ): Observable<any> {
    const partnerCode = this.clientCode;
    let url = this.flexUrl + "/{partnerCode}/search/{date}/{origin}/{destination}?numPassengers={numberOfTravellers}";

    if (date === undefined || date === null) {
      const msg = 'The parameter \'date\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    if (origin === undefined || origin === null) {
      const msg = 'The parameter \'origin\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    if (destination === undefined || destination === null) {
      const msg = 'The parameter \'destination\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    if (numberOfTravellers === undefined || numberOfTravellers === null) {
      const msg = 'The parameter \'numberOfTravellers\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    url = url.replace("{partnerCode}", encodeURIComponent("" + partnerCode));
    url = url.replace("{date}", encodeURIComponent("" + date));
    url = url.replace("{origin}", encodeURIComponent("" + origin));
    url = url.replace("{destination}", encodeURIComponent("" + destination));
    url = url.replace("{numberOfTravellers}", encodeURIComponent("" + numberOfTravellers));
    url = url.replace(/[?&]$/, "");

    return this.httpClient
      .get(url)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMsg: string = this.getServerErrorMessage(error);
          this.messageService.showErrorToast(errorMsg);
          return throwError(errorMsg);
      })
    );
  }

  public initCheckoutSession(session: string, request: any) {
    const partnerCode = this.clientCode;
    let url = this.flexUrl + "/{partnerCode}/{session}/checkout";
    let body = request;

    if (session === undefined || session === null) {
      const msg = 'The parameter \'session\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    if (request === undefined || request === null) {
      const msg = 'The parameter \'request\' must be defined.'
      this.messageService.showErrorToast(msg);
      return throwError(msg);
    }

    url = url.replace("{partnerCode}", encodeURIComponent("" + partnerCode));
    url = url.replace("{session}", encodeURIComponent("" + session));
    url = url.replace(/[?&]$/, "");

    return this.httpClient
      .post<any>(url, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMsg: string = this.getServerErrorMessage(error);
          this.messageService.showErrorToast(errorMsg);
          return throwError(errorMsg);
      })
    );
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404: {
          return `Not Found: ${error.error.message}`;
      }
      case 403: {
          return `Access Denied: ${error.error.message}`;
      }
      case 500: {
          return `Internal Server Error: ${error.error.message}`;
      }
      default: {
          return `Unknown Server Error: ${error.error.message}`;
      }
    }
  }
}
