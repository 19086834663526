import { createSelector } from "@ngrx/store";
import { AppState, selectBookingState } from "./store.state";

export const selectActiveTrips = createSelector(
    selectBookingState,
    (state: AppState) => state.activeTripCalendarTemplate
);

export const selectActiveTripsData = createSelector(
    selectBookingState,
    (state: AppState) => state.activeTripData
);

export const selectStations = createSelector(
    selectBookingState,
    (state: AppState) => state.stations
);

export const selectSession = createSelector(
    selectBookingState,
    (state: AppState) => state.activeTripCalendarTemplate.session
);

export const selectActiveEmail = createSelector(
    selectBookingState,
    (state: AppState) => state.activeTripData.email
);