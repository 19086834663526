import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingEnum } from '@shared/enums/navigation.enum';
import { INavElement } from '@shared/interfaces/navigation.interface';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  helpElementsList: INavElement[] = [
    {
      text: 'Help centre',
      isExternal: true,
      linkTree: [
        RoutingEnum.Help
      ]
    },
    {
      text: 'FAQs',
      linkTree: [
        RoutingEnum.FAQ
      ]
    },
    {
      text: 'Book travel assistance',
      isExternal: true,
      linkTree: [
        RoutingEnum.BookTravelAssistance
      ]
    },
    {
      text: 'Delay repay',
      isExternal: true,
      linkTree: [
        RoutingEnum.DelayRepay
      ]
    },
  ];

  aboutElementsList: INavElement[] = [
    {
      text: 'Privacy policy',
      isExternal: true,
      linkTree: [
        RoutingEnum.PrivacyPolicy
      ]
    },
    {
      text: 'Terms & conditions',
      linkTree: [
        RoutingEnum.TermsAndConditions
      ]
    },
    {
      text: 'Passenger’s charter',
      isExternal: true,
      linkTree: [
        RoutingEnum.PassengerCharter
      ]
    },
  ];

  year: number = new Date().getFullYear();

  constructor(private router: Router,) { }

  linkToHowItWorsks() {
    if (this.router.url === '/') {
      const element = document.querySelector('.how-it-works');
      if (element) element.scrollIntoView({behavior: 'smooth'});
    } else {
      this.router.navigate(['/']);
      setTimeout(() => {
        const element = document.querySelector('.how-it-works');
        if (element) element.scrollIntoView({behavior: 'smooth'});
      }, 1000);
    }
  }

}
