<footer>
  <div class="wrapper">
    <div class="footer-links">
      <div class="footer-links__column first">
        <ul>
          <li class="primary-cta-label">Help centre</li>
          <li>
            <a (click)="linkToHowItWorsks()">How it works</a>
          </li>
          <ng-container *ngFor="let element of helpElementsList">
            <ng-container *ngIf="element?.isExternal; else routerLinkTemplate">
              <li>
                <a [attr.href]="element.linkTree[0]" target="_blank">{{element.text}}</a>
              </li>
            </ng-container>
            <ng-template #routerLinkTemplate>
              <li [routerLink]="element.linkTree">{{element.text}}</li>
            </ng-template>
          </ng-container>
        </ul>
      </div>
      <div class="footer-links__column">
        <ul>
          <li class="primary-cta-label">About us</li>
          <ng-container *ngFor="let element of aboutElementsList">
            <ng-container *ngIf="element?.isExternal; else routerLinkTemplate">
              <li>
                <a [attr.href]="element.linkTree[0]" target="_blank">{{element.text}}</a>
              </li>
            </ng-container>
            <ng-template #routerLinkTemplate>
              <li [routerLink]="element.linkTree">{{element.text}}</li>
            </ng-template>
          </ng-container>
        </ul>
      </div>
      <div class="logo mb-50" routerLink="/">
        <img src="assets/images/Superfare-logo.svg" alt="logo" loading="eager">
      </div>
    </div>
    <div>
      <p class="copyrights">© {{ year }} Seatfrog. All Rights Reserved.</p>
    </div>
  </div>
</footer>

