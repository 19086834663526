import * as Components from './components';
import * as Directives from './directives';
import * as Layouts from './layout';
import * as Pipes from './pipes';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MondayFirstDateAdapter } from "../core/adapters/monday-calendar-adapter";
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';


const LayoutsList = [
  Layouts.ContainerComponent,
  Layouts.AlertBannerComponent,
  Layouts.HeaderComponent,
  Layouts.MainComponent,
  Layouts.FooterComponent,
];

const ComponentsList = [
  Components.SpinnerComponent,
  Components.SvgIconComponent,
  Components.SvgDefinitionsComponent,
  Components.AccordionComponent,
  Components.BlackoutDatesComponent,
  Components.AlertModalComponent,
  Components.CookieConsentBannerComponent
];

const PipesList = [
  Pipes.StationNamePipe
];

const DirectivesList = [
  Directives.LazyImgDirective
]

const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'ddd D MMM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    monthLabel: 'MMMM',
  },
};

@NgModule({
  declarations: [
    ...LayoutsList,
    ...ComponentsList,
    ...PipesList,
    ...DirectivesList,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    RouterModule,
    ToastrModule.forRoot({
      positionClass: 'mg-toast',
      newestOnTop: true,
      preventDuplicates: true,
      maxOpened: 4,
    }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    ...LayoutsList,
    ...ComponentsList,
    ...PipesList,
    ...DirectivesList,
  ],
  providers: [
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    {provide: DateAdapter, useClass: MondayFirstDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class SharedModule { }
