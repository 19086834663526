import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoutingEnum } from '@shared/enums/navigation.enum';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule),
  },
  {
    path: RoutingEnum.Booking,
    loadChildren: () => import('./features/booking/booking.module').then(m => m.BookingModule),
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
