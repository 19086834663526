import { Injectable } from "@angular/core";
import { LocalStorageService } from "../services/local-storage.service";
import { AppState } from "./store.state";
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from "@core/services/message.service";
import { map, switchMap, tap } from "rxjs/operators";
import { BookingService } from "@core/services/booking.service";
import { getStationsList, getStationsListFailure, getStationsListSuccess, initCheckoutSession, initCheckoutSessionFailure, initCheckoutSessionSuccess, searchAvailableTrips, searchAvailableTripsFailure, searchAvailableTripsSuccess, setActiveTripData } from "./store.actions";
import { Router } from "@angular/router";
import { RoutingEnum } from "@shared/enums/navigation.enum";

@Injectable()
export class StoreEffects {

  getStationsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStationsList),
      switchMap(() => this.bookingService.fetchStationsList()
        .pipe(
          map(res => res.stations
            ? getStationsListSuccess({ data: res.stations })
            : getStationsListFailure({ errors: res.message })
          ),
        )
      ),
    )
  );

  searchAvailableTrips$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchAvailableTrips),
      switchMap(({date, origin, destination, numberOfTravellers}) => this.bookingService.searchForDepartures(date, origin, destination, numberOfTravellers)
        .pipe(
          map(res => res.outbound.days.length > 0 && res.bands.length > 0
            ? searchAvailableTripsSuccess({ data: res })
            : searchAvailableTripsFailure({ errors: res.message })
          ),
        )
      ),
    )
  );

  initCheckoutSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initCheckoutSession),
      switchMap(({ data }) => this.bookingService.initCheckoutSession(data?.session, data?.request)
        .pipe(
          map(res => res
            ? initCheckoutSessionSuccess({ data: res })
            : initCheckoutSessionFailure({ errors: res.message })
          ),
        )
      ),
    )
  );

  getStationsListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStationsListSuccess),
      tap((action) => this.localStorageService.setItem("STATIONS", action.data)),
    ),
    { dispatch: false },
  );

  searchAvailableTripsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchAvailableTripsSuccess),
      tap((action) => this.localStorageService.setItem("ACTIVE_TRIP_TEMPLATE", action.data)),
      tap(() => this.router.navigate([RoutingEnum.Booking])),
    ),
    { dispatch: false },
  );

  setActiveTripData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setActiveTripData),
      tap((action) => this.localStorageService.setItem("ACTIVE_TRIP_DATA", action.tripData)),
    ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
    private messageService: MessageService,
    private bookingService: BookingService,
  ) { }
}
